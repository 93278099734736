"use client";
import React, { useState, useEffect } from "react";
import {
  useForm,
  UseFormRegister,
  FieldErrors,
  UseFormWatch,
  UseFormSetValue,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { Button } from "./ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "./ui/card";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { Checkbox } from "./ui/checkbox";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { toast } from "./ui/use-toast";
import { FingerPrintAuth, type FingerPrintAuthProps } from "./ui/Fingerprint";
import "../styles/globals.css";
import { Loader2 } from "lucide-react";
import axios from "axios";
import { Path } from "react-hook-form";
import { response } from "express";
import uploadFingerprint from "./ui/firebase";
import { calculateAge } from "./ui/calculateAge";

type FormData = z.infer<typeof formSchema>;
const EmployeeInfoForm = ({
  register,
  errors,
  watch,
  setValue,
}: {
  register: UseFormRegister<FormData>;
  errors: FieldErrors<FormData>;
  watch: UseFormWatch<FormData>;
  setValue: UseFormSetValue<FormData>;
}) => {
  // const NIC = watch("NIC");
  const employeeCode = watch("EmployeeCode"); // Watching EmployeeCode
  const [isLoading, setIsLoading] = useState(false);
  const [issurveyed, setIssurveyed] = useState(false);

  useEffect(() => {
    const fetchEmployeeData = async () => {
      if (employeeCode && employeeCode.length > 0) {
        // Assuming EmployeeCode should be non-empty
        setIsLoading(true);
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_FETCH_EMPLOYEE_BY_ID}?docId=${employeeCode}`
          );

          if (response.data) {
            setIssurveyed(response.data.status);
            if (!issurveyed) {
              const employee = response.data;
              console.log("data", employee);
              setValue("EmployeeCode", employee.EmployeeCode || "");
              setValue("EmployeeName", employee.EmployeeName || "");
              setValue("Gender", employee.Gender || "");
              setValue("Designation", employee.Designation || "");
              setValue("Department", employee.Department || "");
              setValue("BranchName", employee.BranchName || "");
              setValue("age", String(calculateAge(employee.dateOfBirth)) || "");
              setValue("NIC", employee.NIC || "");

              setValue("FamilyPersonName", employee.FamilyPersonName || "");
              setValue("FamilyRelation", employee.FamilyRelation);
              console.log(employee);
            } else {
              //display a modal that pops up and says that the employee has already given the survey
              //and that they can't give it again
            }
          }
        } catch (error) {
          errors.EmployeeCode = {
            type: "manual",
            message: "Employee not found/Check your Internet Connection",
          };
          setValue("EmployeeName", "");
          setValue("Gender", "Other");
          setValue("Designation", "");
          setValue("Department", "");
          setValue("BranchName", "");
          setValue("NIC", "");
          setValue("age", "");
          setIsLoading(false);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchEmployeeData();
  }, [employeeCode, setValue]); // Dependency updated to EmployeeCode

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold">Employee Information</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <Label htmlFor="EmployeeCode">
            Employee ID<span style={{ color: "red" }}> *</span>
          </Label>
          <div className="relative">
          <Input
            id="EmployeeCode"
            {...register("EmployeeCode")}
            className="mt-1"
          />
          {isLoading && (
            <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
              <Loader2 className="h-5 w-5 animate-spin text-gray-400" />
            </div>
          )}
          </div>
          {errors.EmployeeCode && (
            <p className="text-red-500 text-sm mt-1">
              {errors.EmployeeCode.message}
            </p>
          )}
        </div>
        <div>
          <Label htmlFor="NIC">
            National Identity Card (NIC) Number
            <span style={{ color: "red" }}> *</span>
          </Label>
          <div className="relative">
            <Input
              disabled
              id="NIC"
              {...register("NIC")}
              placeholder="For eg: 4130512345678"
              className="mt-1 pr-10"
            />
          </div>
          {errors.NIC && (
            <p className="text-red-500 text-sm mt-1">{errors.NIC.message}</p>
          )}
        </div>
        <div>
          <Label htmlFor="EmployeeName">
            Full Name<span style={{ color: "red" }}> *</span>
          </Label>
          <Input
            id="EmployeeName"
            disabled
            {...register("EmployeeName")}
            className="mt-1"
          />
          {errors.EmployeeName && (
            <p className="text-red-500 text-sm mt-1">
              {errors.EmployeeName.message}
            </p>
          )}
        </div>
        <div>
          <Label htmlFor="Gender">
            Gender <span style={{ color: "red" }}> * </span>
          </Label>
          <Select
            disabled
            onValueChange={(value) =>
              setValue("Gender", value as "Male" | "Female" | "Other")
            }
            value={watch("Gender") || undefined}
          >
            <SelectTrigger className="mt-1">
              <SelectValue placeholder="Select Gender" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="Male">Male</SelectItem>
              <SelectItem value="Female">Female</SelectItem>
              <SelectItem value="Other">Other</SelectItem>
            </SelectContent>
          </Select>
          {errors.Gender && (
            <p className="text-red-500 text-sm mt-1">{errors.Gender.message}</p>
          )}
        </div>
        <div>
          <Label htmlFor="Department">
            Department/Unit<span style={{ color: "red" }}> *</span>
          </Label>
          <Input
            id="Department"
            disabled
            {...register("Department")}
            className="mt-1"
          />
          {errors.Department && (
            <p className="text-red-500 text-sm mt-1">
              {errors.Department.message}
            </p>
          )}
        </div>
        <div>
          <Label htmlFor="Designation">
            Designation<span style={{ color: "red" }}> *</span>
          </Label>
          <Input
            id="Designation"
            disabled
            {...register("Designation")}
            className="mt-1"
          />
          {errors.Designation && (
            <p className="text-red-500 text-sm mt-1">
              {errors.Designation.message}
            </p>
          )}
        </div>
        <div>
          <Label htmlFor="Designation">
            BranchName<span style={{ color: "red" }}> *</span>
          </Label>
          <Input
            id="BranchName"
            disabled
            {...register("BranchName")}
            className="mt-1"
          />
          {errors.BranchName && (
            <p className="text-red-500 text-sm mt-1">
              {errors.BranchName.message}
            </p>
          )}
        </div>
        <div>
          <Label htmlFor="contactNumber">Contact Number</Label>
          <Input
            id="contactNumber"
            {...(register("contactNumber") || "")}
            className="mt-1"
          />
          {errors.contactNumber && (
            <p className="text-red-500 text-sm mt-1">
              {errors.contactNumber.message}
            </p>
          )}
        </div>
        <div>
          <Label htmlFor="bloodGroup">Blood Group</Label>
          <Select
            onValueChange={(value: string) => setValue("bloodGroup", value)}
          >
            <SelectTrigger className="mt-1">
              <SelectValue placeholder="Select blood group" />
            </SelectTrigger>
            <SelectContent>
              {["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"].map(
                (group) => (
                  <SelectItem key={group} value={group}>
                    {group}
                  </SelectItem>
                )
              )}
            </SelectContent>
          </Select>
          {errors.bloodGroup && (
            <p className="text-red-500 text-sm mt-1">
              {errors.bloodGroup.message}
            </p>
          )}
        </div>
        <div>
          <Label htmlFor="age">Age</Label>
          <Input id="age" {...(register("age") || "")} className="mt-1" />
          {errors.age && (
            <p className="text-red-500 text-sm mt-1">{errors.age.message}</p>
          )}
        </div>
        {/* Fingerprint Modal */}
        {issurveyed && (
          <div className="fixed inset-0 bg-slate-950 bg-opacity-50 flex items-center justify-center z-50 transition-opacity duration-300 ease-in-out">
            <div className="bg-black p-6 rounded-lg shadow-lg transform transition-transform duration-300 ease-in-out">
              <h2 className="text-xl  text-white font-semibold mb-4">
                Survey Status
              </h2>
              <p className=" text-white mb-4">
                This Employee has already been surveyed<br></br> Press OK to
                continue.
              </p>
              <Button
                onClick={() => {
                  setValue("EmployeeCode", "");
                  setValue("EmployeeName", "");
                  setValue("Gender", undefined);
                  setValue("Designation", " ");
                  setValue("Department", " ");
                  setValue("BranchName", " ");
                  setValue("NIC", " ");
                  setIsLoading(false);
                  setIssurveyed(false);
                }}
                className="mt-4 aligh-center"
              >
                OK
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const EmergencyContactForm = ({
  register,
  errors,
}: {
  register: UseFormRegister<FormData>;
  errors: FieldErrors<FormData>;
}) => (
  <div className="space-y-4">
    <h3 className="text-lg font-semibold">Emergency Contact Information</h3>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div>
        <Label htmlFor="FamilyPersonName">
          Name<span style={{ color: "red" }}> *</span>
        </Label>
        <Input
          id="FamilyPersonName"
          disabled
          {...register("FamilyPersonName")}
          className="mt-1"
        />
        {errors.FamilyPersonName && (
          <p className="text-red-500 text-sm mt-1">
            {errors.FamilyPersonName.message}
          </p>
        )}
      </div>
      <div>
        <Label htmlFor="FamilyRelation">
          Relationship<span style={{ color: "red" }}> *</span>
        </Label>
        <Input
          id="FamilyRelation"
          disabled
          {...register("FamilyRelation")}
          className="mt-1"
        />
        {errors.FamilyRelation && (
          <p className="text-red-500 text-sm mt-1">
            {errors.FamilyRelation.message}
          </p>
        )}
      </div>
      <div>
        <Label htmlFor="emergencyContact">Contact Number</Label>
        <Input
          id="emergencyContact"
          {...(register("emergencyContact") || "")}
          className="mt-1"
        />
        {errors.emergencyContact && (
          <p className="text-red-500 text-sm mt-1">
            {errors.emergencyContact.message}
          </p>
        )}
      </div>
    </div>
  </div>
);

const MedicalDeclarationForm = ({
  register,
  watch,
  setValue,
  errors,
}: {
  register: UseFormRegister<FormData>;
  watch: UseFormWatch<FormData>;
  setValue: UseFormSetValue<FormData>;
  errors: FieldErrors<FormData>;
}) => {
  const medicalConditions = watch("medicalConditions") || {};
  const noMedicalConditions = watch("noMedicalConditions");
  const currentMedication = watch("currentMedication");
  const majorSurgeries = watch("majorSurgeries");
  const physicalLimitations = watch("physicalLimitations");
  const regularCheckups = watch("regularCheckups");
  const smoker = watch("smoker");
  const addictions = watch("addictions");

  useEffect(() => {
    const setDefaultIfFalsy = (field: string, defaultValue: string) => {
      const currentValue = watch(field as Path<FormData>);
      if (!currentValue) {
        setValue(field as Path<FormData>, defaultValue);
      }
    };

    // Set default values for medical specifications
    const medicalSpecFields = [
      "covid19",
      "cancer",
      "mentalHealth",
      "allergies",
      "otherChronicIllness",
    ];
    medicalSpecFields.forEach((field) => {
      setDefaultIfFalsy(
        `medicalSpecifications.${field}`,
        `No-${field.charAt(0).toUpperCase() + field.slice(1)}`
      );
    });
  }, [watch, setValue]);

  const medicalConditionsList = [
    { id: "hypertension", label: "Hypertension (High Blood Pressure)" },
    { id: "diabetes", label: "Diabetes" },
    { id: "cardiovascular", label: "Cardiovascular Diseases (Heart-related)" },
    { id: "respiratory", label: "Asthma or Other Respiratory Conditions" },
    { id: "hepatitis", label: "Hepatitis (A, B, or C)" },
    { id: "tuberculosis", label: "Tuberculosis (TB)" },
    { id: "kidney", label: "Kidney Diseases" },
    { id: "epilepsy", label: "Epilepsy or Seizures" },
    { id: "chronicPain", label: "Chronic Back or Joint Pain" },
    { id: "hiv", label: "HIV" },
    {
      id: "covid19",
      label: "Suffered from COVID-19, Dengue or Typhoid etc.",
      specify: true,
    },
    { id: "cancer", label: "Cancer", specify: true },
    {
      id: "mentalHealth",
      label: "Mental Health Conditions (e.g., Depression)",
      specify: true,
    },
    { id: "allergies", label: "Any Allergies", specify: true },
    {
      id: "otherChronicIllness",
      label: "Other Chronic Illnesses",
      specify: true,
    },
  ];

  // Initialize all medical conditions to false if they're undefined
  useEffect(() => {
    medicalConditionsList.forEach(({ id }) => {
      if (
        medicalConditions[id as keyof typeof medicalConditions] === undefined
      ) {
        setValue(`medicalConditions.${id}` as Path<FormData>, false);
      }
    });
  }, [medicalConditions, setValue]);

  const handleNoMedicalConditionsChange = (checked: boolean) => {
    setValue("noMedicalConditions", checked);
    if (checked) {
      // If "No Medical Conditions" is checked, uncheck all other conditions
      medicalConditionsList.forEach(({ id }) => {
        setValue(`medicalConditions.${id}` as Path<FormData>, false);
        setValue(`medicalSpecifications.${id}` as Path<FormData>, "");
      });
      setValue("currentMedication", false);
      setValue("currentMedicationDetails", "");
      setValue("majorSurgeries", false);
      setValue("majorSurgeriesDetails", "");
      setValue("physicalLimitations", false);
      setValue("physicalLimitationsDetails", "");
      setValue("regularCheckups", false);
      setValue("regularCheckupsDetails", "");
      setValue("smoker", false);
      setValue("addictions", false);
      setValue("addictionSpecification", "");
    }
  };

  return (
    <>
      <div className="space-y-4">
        <h3 className="text-lg font-semibold">Medical Declaration</h3>
        <p className="text-sm text-muted-foreground">
          Please answer the following questions voluntarily
        </p>

        <div className="flex items-center space-x-2">
          <Checkbox
            id="noMedicalConditions"
            checked={noMedicalConditions}
            onCheckedChange={handleNoMedicalConditionsChange}
          />
          <Label htmlFor="noMedicalConditions" className="text-sm font-medium">
            No Medical Conditions/Addictions
          </Label>
        </div>

        {!noMedicalConditions && (
          <>
            <div className="space-y-4">
              {medicalConditionsList.map(({ id, label, specify }) => (
                <div key={id} className="flex flex-col space-y-2">
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id={id}
                      checked={
                        medicalConditions[
                          id as keyof typeof medicalConditions
                        ] || false
                      }
                      onCheckedChange={(checked) => {
                        setValue(
                          `medicalConditions.${id}` as Path<FormData>,
                          checked as boolean
                        );
                        if (!checked) {
                          setValue(
                            `medicalSpecifications.${id}` as Path<FormData>,
                            ""
                          );
                        }
                      }}
                    />
                    <Label htmlFor={id} className="text-sm">
                      {label}
                    </Label>
                  </div>
                  {specify &&
                    medicalConditions[id as keyof typeof medicalConditions] && (
                      <Input
                        placeholder="Please specify"
                        {...register(
                          `medicalSpecifications.${id}` as Path<FormData>
                        )}
                        className="ml-6 w-3/4"
                      />
                    )}
                </div>
              ))}
            </div>

            <div className="space-y-2">
              <div className="flex items-center space-x-2">
                <Checkbox
                  id="currentMedication"
                  checked={currentMedication}
                  onCheckedChange={(checked) =>
                    setValue("currentMedication", checked as boolean)
                  }
                />
                <Label htmlFor="currentMedication" className="text-sm">
                  Are you currently on any regular medication?
                </Label>
              </div>
              {currentMedication && (
                <Input
                  placeholder="Please specify medication"
                  {...register("currentMedicationDetails")}
                  className="ml-6 w-3/4"
                />
              )}
            </div>

            <div className="space-y-2">
              <div className="flex items-center space-x-2">
                <Checkbox
                  id="majorSurgeries"
                  checked={majorSurgeries}
                  onCheckedChange={(checked) =>
                    setValue("majorSurgeries", checked as boolean)
                  }
                />
                <Label htmlFor="majorSurgeries" className="text-sm">
                  Have you undergone any major surgeries or medical procedures
                  in the last 5 years?
                </Label>
              </div>
              {majorSurgeries && (
                <Input
                  placeholder="Please specify surgeries or procedures"
                  {...register("majorSurgeriesDetails")}
                  className="ml-6 w-3/4"
                />
              )}
            </div>

            <div className="space-y-2">
              <div className="flex items-center space-x-2">
                <Checkbox
                  id="physicalLimitations"
                  checked={physicalLimitations}
                  onCheckedChange={(checked) =>
                    setValue("physicalLimitations", checked as boolean)
                  }
                />
                <Label htmlFor="physicalLimitations" className="text-sm">
                  Do you have any physical limitations that may affect your
                  ability to perform your job?
                </Label>
              </div>
              {physicalLimitations && (
                <Input
                  placeholder="Please specify physical limitations"
                  {...register("physicalLimitationsDetails")}
                  className="ml-6 w-3/4"
                />
              )}
            </div>

            <div className="space-y-2">
              <div className="flex items-center space-x-2">
                <Checkbox
                  id="regularCheckups"
                  checked={regularCheckups}
                  onCheckedChange={(checked) =>
                    setValue("regularCheckups", checked as boolean)
                  }
                />
                <Label htmlFor="regularCheckups" className="text-sm">
                  Do you require regular medical check-ups or monitoring for any
                  chronic condition?
                </Label>
              </div>
              {regularCheckups && (
                <Input
                  placeholder="Please specify check-ups or monitoring required"
                  {...register("regularCheckupsDetails")}
                  className="ml-6 w-3/4"
                />
              )}
            </div>

            <div className="space-y-2">
              <div className="flex items-center space-x-2">
                <Checkbox
                  id="smoker"
                  checked={smoker}
                  onCheckedChange={(checked) =>
                    setValue("smoker", checked as boolean)
                  }
                />
                <Label htmlFor="smoker" className="text-sm">
                  Are you a smoker?
                </Label>
              </div>
            </div>

            <div className="space-y-2">
              <div className="flex items-center space-x-2">
                <Checkbox
                  id="addictions"
                  checked={addictions}
                  onCheckedChange={(checked) =>
                    setValue("addictions", checked as boolean)
                  }
                />
                <Label htmlFor="addictions" className="text-sm">
                  Are you addicted (Pan, Ghutka, or other)?
                </Label>
              </div>
              {addictions && (
                <Input
                  placeholder="Please specify addiction"
                  {...register("addictionSpecification")}
                  className="ml-6 w-3/4"
                />
              )}
            </div>
          </>
        )}

        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            <Label htmlFor="hasRemarks" className="text-sm">
              Remarks
            </Label>
          </div>
          <div className="ml-6">
            <Input
              placeholder="Enter remarks here"
              {...register("remarks")}
              className="w-3/4"
            />
            {errors.remarks && (
              <p className="text-red-500 text-sm">{errors.remarks.message}</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

// Update the formSchema
const formSchema = z.object({
  EmployeeName: z
    .string()
    .min(2, { message: "Full name must be at least 2 characters." })
    .regex(/^[A-Za-z\s\-'.]+$/, { message: "Name must contain only letters, spaces, hyphens, apostrophes, and periods" }),
  EmployeeCode: z.string().min(1, { message: "Employee ID is required." }),
  Department: z.string().min(1, { message: "Department is required." }),
  Gender: z
    .enum(["Male", "Female", "Other"], {
      required_error: "Gender is required.",
    })
    .optional(),
  BranchName: z
    .string()
    .min(1, { message: "BranchName is required." })
    .optional(),
  Designation: z.string().min(1, { message: "Designation is required." }),
  contactNumber: z
    .string()
    .regex(/^$|^03\d{9}$/, { message: "Please enter a valid phone number." }) // Matches an empty string or a valid phone number
    .optional()
    .nullable()
    .default(null),
  //age
  age: z
    .string()
    .regex(/^\d*$/, { message: "Age must contain only numbers" })
    .max(100, { message: "Age must be at most 100." })
    .optional()
    .nullable()
    .default(""),
  dateOfBirth: z
    .string()
    .refine((date) => !isNaN(Date.parse(date)), {
      message: "Please enter a valid date.",
    })
    .optional(),
  bloodGroup: z.string().optional(),
  NIC: z.string().min(13, { message: "NIC must be at least 13 characters." }),
  FamilyPersonName: z
    .string()
    .min(1, { message: "Emergency contact name is required." })
    .regex(/^[A-Za-z\s\-'.]+$/, { message: "Name must contain only letters, spaces, hyphens, apostrophes, and periods" }),
  FamilyRelation: z
    .string()
    .min(1, { message: "Emergency contact relationship is required." }),
  emergencyContact: z
    .string()
    .regex(/^$|^03\d{9}$/, { message: "Please enter a valid phone number." }) // Matches an empty string or a valid phone number
    .optional()
    .nullable()
    .default(null),
  medicalConditions: z
    .object({
      hypertension: z.boolean(),
      diabetes: z.boolean(),
      cardiovascular: z.boolean(),
      respiratory: z.boolean(),
      hepatitis: z.boolean(),
      tuberculosis: z.boolean(),
      kidney: z.boolean(),
      epilepsy: z.boolean(),
      chronicPain: z.boolean(),
      hiv: z.boolean(),
      covid19: z.boolean(),
      cancer: z.boolean(),
      mentalHealth: z.boolean(),
      allergies: z.boolean(),
      otherChronicIllness: z.boolean(),
    })
    .default({
      hypertension: false,
      diabetes: false,
      cardiovascular: false,
      respiratory: false,
      hepatitis: false,
      tuberculosis: false,
      kidney: false,
      epilepsy: false,
      chronicPain: false,
      hiv: false,
      covid19: false,
      cancer: false,
      mentalHealth: false,
      allergies: false,
      otherChronicIllness: false,
    }),
  medicalSpecifications: z
    .object({
      covid19: z.string().optional().default("No-COVID"),
      cancer: z.string().optional().default("No-Cancer"),
      mentalHealth: z.string().optional().default("No-Mental Health"),
      allergies: z.string().optional().default("No-Allergies"),
      otherChronicIllness: z
        .string()
        .optional()
        .default("No-Other Chronic Illness"),
    })
    .optional(),
  currentMedication: z.boolean().optional().default(false),
  currentMedicationDetails: z
    .string()
    .optional()
    .default("No-Medication Details"),
  majorSurgeries: z.boolean().optional().default(false),
  majorSurgeriesDetails: z
    .string()
    .optional()
    .default("No-Major Surgeries Details"),
  physicalLimitations: z.boolean().optional().default(false),
  physicalLimitationsDetails: z
    .string()
    .optional()
    .default("No-Physical Limitations Details"),
  regularCheckups: z.boolean().optional().default(false),
  regularCheckupsDetails: z
    .string()
    .optional()
    .default("No-Regular Checkups Details"),
  smoker: z.boolean().optional().default(false),
  smokerDetails: z.string().optional().default("No-Smoker Details"),
  addictions: z.boolean().optional().default(false),
  addictionSpecification: z
    .string()
    .optional()
    .default("No-Addiction Specification"),
  noMedicalConditions: z.boolean().optional().default(false),
  hasRemarks: z.boolean().optional().default(false),
  remarks: z
    .string()
    .max(100, "Remarks must not exceed 100 characters")
    .optional()
    .default(""),
  status: z.boolean().optional().default(false),
});

export function HealthSurveyComponent() {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    setValue,
    getValues,
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(formSchema),
    mode: "onChange",
    defaultValues: {
      medicalSpecifications: {
        covid19: "No-COVID",
        cancer: "No-Cancer",
        mentalHealth: "No-Mental Health",
        allergies: "No-Allergies",
        otherChronicIllness: "No-Other Chronic Illness",
      },
      currentMedication: false,
      currentMedicationDetails: "No-Medication Details",
      majorSurgeries: false,
      majorSurgeriesDetails: "No-Major Surgeries Details",
      physicalLimitations: false,
      physicalLimitationsDetails: "No-Physical Limitations Details",
      regularCheckups: false,
      regularCheckupsDetails: "No-Regular Checkups Details",
      smoker: false,
      smokerDetails: "No-Smoker Details",
      addictions: false,
      addictionSpecification: "No-Addiction Specification",
      noMedicalConditions: false,
      hasRemarks: false,
      remarks: "",
    },
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [step, setStep] = useState(1);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [showFingerprint, setShowFingerprint] = useState(false);
  const employeeCode = watch("EmployeeCode");
  const handleFingerprintSuccess = async () => {
    setShowFingerprint(false);
    // Proceed with form submission
    await submitForm(getValues());
  };

  const submitForm = async (data: FormData) => {
    setIsSubmitting(true);
    setSubmitError(null);
    setSubmitSuccess(false);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_SUBMIT_SURVEY}`,
        data
      );

      if (response.status === 200) {
        setSubmitSuccess(true);
        toast({
          title: "Survey Submitted",
          description: "Your health survey has been successfully submitted.",
        });
        reset({
          EmployeeCode: "",
          EmployeeName: "",
          Gender: undefined,
          Designation: "",
          Department: "",
          BranchName: "",
          NIC: "",
          contactNumber: "",
          age: "",
          dateOfBirth: "",
          bloodGroup: "",
          FamilyPersonName: "",
          FamilyRelation: "",
          emergencyContact: "",
          medicalConditions: {
            hypertension: false,
            diabetes: false,
            cardiovascular: false,
            respiratory: false,
            hepatitis: false,
            tuberculosis: false,
            kidney: false,
            epilepsy: false,
            chronicPain: false,
            hiv: false,
            covid19: false,
            cancer: false,
            mentalHealth: false,
            allergies: false,
            otherChronicIllness: false,
          },
          medicalSpecifications: {
            covid19: "No-COVID",
            cancer: "No-Cancer",
            mentalHealth: "No-Mental Health",
            allergies: "No-Allergies",
            otherChronicIllness: "No-Other Chronic Illness",
          },
          currentMedication: false,
          currentMedicationDetails: "No-Medication Details",
          majorSurgeries: false,
          majorSurgeriesDetails: "No-Major Surgeries Details",
          physicalLimitations: false,
          physicalLimitationsDetails: "No-Physical Limitations Details",
          regularCheckups: false,
          regularCheckupsDetails: "No-Regular Checkups Details",
          smoker: false,
          smokerDetails: "No-Smoker Details",
          addictions: false,
          addictionSpecification: "No-Addiction Specification",
          noMedicalConditions: false,
          hasRemarks: false,
          remarks: "",
        });
      } else {
        throw new Error("Submission failed");
      }
    } catch (error) {
      console.error("Error submitting survey:", error);
      setSubmitError("Failed to submit survey. Please try again.");
      toast({
        title: "Submission Failed",
        description:
          "There was an error submitting your survey. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const hasMedicalDeclaration = () => {
    const noMedicalConditions = watch("noMedicalConditions");
    const medicalConditions = watch("medicalConditions") || {};
    const currentMedication = watch("currentMedication");
    const majorSurgeries = watch("majorSurgeries");
    const physicalLimitations = watch("physicalLimitations");
    const regularCheckups = watch("regularCheckups");
    const smoker = watch("smoker");
    const addictions = watch("addictions");

    if (noMedicalConditions) return true;
    if (Object.values(medicalConditions).some((value) => value === true))
      return true;
    if (
      currentMedication ||
      majorSurgeries ||
      physicalLimitations ||
      regularCheckups ||
      smoker ||
      addictions
    )
      return true;
    return false;
  };

  const onSubmit = async (data: FormData) => {
    // setShowFingerprint(true);
    handleFingerprintSuccess();
  };

  return (
    <>
      <Card className="w-full max-w-4xl mx-auto">
        <CardHeader>
          <CardTitle className="text-2xl">
            Employee Health Information Survey
          </CardTitle>
          <CardDescription>
            This form is designed to capture necessary health information to
            ensure a safe and healthy workplace environment. The information
            provided will be kept confidential.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
            {step === 1 && (
              <EmployeeInfoForm
                register={register}
                errors={errors}
                watch={watch}
                setValue={setValue}
              />
            )}
            {step === 2 && (
              <EmergencyContactForm register={register} errors={errors} />
            )}
            {step === 3 && (
              <MedicalDeclarationForm
                register={register}
                watch={watch}
                setValue={setValue}
                errors={errors}
              />
            )}

            <div className="flex justify-end gap-4 mt-4">
              {step > 1 && (
                <Button
                  type="button"
                  onClick={() => setStep(step - 1)}
                  disabled={isSubmitting}
                >
                  Previous
                </Button>
              )}
              {step < 3 && (
                <Button
                  type="button"
                  onClick={() => setStep(step + 1)}
                  disabled={
                    isSubmitting ||
                    (step === 1 &&
                      (!watch("NIC") ||
                        !watch("EmployeeName") ||
                        !watch("EmployeeCode") ||
                        !watch("Department") ||
                        !watch("Designation"))) ||
                    (step === 2 &&
                      (!watch("FamilyPersonName") || !watch("FamilyRelation")))
                  }
                >
                  Next
                </Button>
              )}
              {step === 3 && (
                <Button
                  type="submit"
                  className="w-40"
                  disabled={isSubmitting || !hasMedicalDeclaration()}
                >
                  {isSubmitting ? (
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  ) : null}
                  {isSubmitting ? "Submitting..." : "Submit Survey"}
                </Button>
              )}
            </div>

            {submitError && <p className="text-red-500 mt-2">{submitError}</p>}
            {submitSuccess && (
              <div className="fixed inset-0 bg-slate-950 bg-opacity-50 flex items-center justify-center z-50 transition-opacity duration-300 ease-in-out">
                <div className="bg-white p-6 rounded-lg shadow-lg transform transition-transform duration-300 ease-in-out">
                  <h2 className="text-xl text-black font-semibold mb-4">
                    Survey Status
                  </h2>
                  <p className="text-black mb-4">
                    Survey submitted successfully!
                  </p>
                  <Button
                    onClick={() => {
                      setStep(1);
                      setValue("EmployeeCode", "");
                      setValue("EmployeeName", "");
                      setValue("Gender", undefined);
                      setValue("Designation", "");
                      setValue("Department", "");
                      setValue("BranchName", "");
                      setValue("NIC", "");
                      setSubmitSuccess(false);
                    }}
                    className="mt-4"
                  >
                    OK
                  </Button>
                </div>
              </div>
            )}
          </form>
        </CardContent>
      </Card>

      {/* Fingerprint Modal */}
      {showFingerprint && (
        <div className="fixed inset-0  bg-slate-950  bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-background p-4 rounded-lg">
            <FingerPrintAuth
              onSuccess={handleFingerprintSuccess}
              onCancel={() => setShowFingerprint(false)}
              EmployeeCode={employeeCode}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default HealthSurveyComponent;
