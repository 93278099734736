export const calculateAge = (dateOfBirth: string): number => {
    // Replace dashes with slashes to handle both "DD/MM/YYYY" and "DD-MM-YYYY" formats
    const formattedDateOfBirth = dateOfBirth.replace(/-/g, "/");
    // Split the date string and convert parts to numbers
    const [day, month, year] = formattedDateOfBirth.split("/").map(Number);
    const birthDate = new Date(year, month - 1, day);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };